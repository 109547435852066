import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function Footer() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <footer
        className="fl-page-footer-wrap"
        itemScope="itemscope"
        itemType="https://schema.org/WPFooter"
        role="contentinfo"
      >
      <NavContainer>
                <div
                  className="fl-col fl-node-60b0033d27919 fl-col-has-cols"
                  data-node="60b0033d27919"
                >
                  <div className="fl-col-content fl-node-content">
                    <div
                      className="fl-module fl-module-rich-text fl-node-60b0033d2792b"
                      data-node="60b0033d2792b"
                    >
                      <div className="fl-module-content fl-node-content">
                        <div className="fl-rich-text">
                          <p><font color="white">{t("body.texto17")}</font></p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-module fl-module-separator fl-node-60b0033d2792c"
                      data-node="60b0033d2792c"
                    >
                      <div className="fl-module-content fl-node-content">
                        <div className="fl-separator" />
                      </div>
                    </div>

                    <div
                      className="fl-col-group fl-node-60b0033d2792d fl-col-group-nested"
                      data-node="60b0033d2792d"
                    >
                      <div
                        className="fl-col fl-node-60b0033d2792e"
                        data-node="60b0033d2792e"
                      >
                        <div className="fl-col-content fl-node-content">
                          <div
                            className="fl-module fl-module-rich-text fl-node-60b0033d27930"
                            data-node="60b0033d27930"
                          >
                            <div className="fl-module-content fl-node-content">
                              <div className="fl-rich-text">
                                <p>
                                <font color="white">
                                <i className="fas fa-phone" />
                                  <strong> {t("body.texto18")}</strong>
                                  </font>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-col-group fl-node-60b0033d2792d fl-col-group-nested"
                      data-node="60b0033d2792d"
                    >
                      <div
                        className="fl-col fl-node-60b0033d2792e"
                        data-node="60b0033d2792e"
                      >
                        <div className="fl-col-content fl-node-content">
                          <div
                            className="fl-module fl-module-rich-text fl-node-60b0033d27930"
                            data-node="60b0033d27930"
                          >
                            <div className="fl-module-content fl-node-content">
                              <div className="fl-rich-text">
                                <p>
                                <font color="white">
                                  <i className="fas fa-envelope" />
                                  <strong> {t("body.texto20")}</strong>
                                  </font>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      </NavContainer>

        <div className="fl-page-footer">
          <div className="fl-page-footer-container container">
            <div className="fl-page-footer-row row">
              <div className="col-md-12 text-center clearfix">
                <div className="fl-page-footer-text fl-page-footer-text-1">
                  Copyright 2024 © Invest Allies | {t("footer.texto2")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

const NavContainer = styled.nav`
padding: .1rem;
background: linear-gradient(to bottom right, #00225b 50%, #b16c29);
display: flex;
align-items: center;
justify-content: space-between;
`;
