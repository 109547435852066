import './App.css';
import Navbar from './componenst/Navbar';
import Footer from "./componenst/Footer";
import Home from './componenst/Home';
import Espana from './componenst/Espana';
import Malta from './componenst/Malta';
import Marrueco from './componenst/Marrueco';
import Infutilespana from './componenst/Infutilespana';
import Infutildubai from './componenst/Infutildubai';
import Infutilmalta from './componenst/Infutilmalta';
import Proyectosespana from './componenst/Proyectosespana';
import Proyectosdubai from './componenst/Proyectosdubai';
import Proyectosmalta from './componenst/Proyectosmalta';
import Impuestosespana from './componenst/Impuestosespana';
import Impuestosdubai from './componenst/Impuestosdubai';
import Impuestosmalta from './componenst/Impuestosmalta';
import Residenciainversorespana from './componenst/Residenciainversorespana';
import Residenciainversordubai from './componenst/Residenciainversordubai';
import Residenciainversormalta from './componenst/Residenciainversormalta';
import Hotelmontereyespana from './componenst/Hotelmontereyespana';
import Hoteldubai from './componenst/Hoteldubai';
import Hotelmalta from './componenst/Hotelmalta';
import Barbarselonaespana from './componenst/Barbarselonaespana';
import Bardubai from './componenst/Bardubai';
import Barmalta from './componenst/Barmalta';
import Edificioespana from './componenst/Edificioespana';
import Edificiodubai from './componenst/Edificiodubai';
import Edificiomalta from './componenst/Edificiomalta';
import Dubai from './componenst/Dubai';
import Error404 from './componenst/Error404';
//import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/espana' element={<Espana/>}/>
        <Route path='/malta' element={<Malta/>}/>
        <Route path='/marrueco' element={<Marrueco/>}/>
        <Route path='/infutilespana' element={<Infutilespana/>}/>
        <Route path='/infutildubai' element={<Infutildubai/>}/>
        <Route path='/infutilmalta' element={<Infutilmalta/>}/>
        <Route path='/proyectosespana' element={<Proyectosespana/>}/>
        <Route path='/proyectosdubai' element={<Proyectosdubai/>}/>
        <Route path='/proyectosmalta' element={<Proyectosmalta/>}/>
        <Route path='/impuestosespana' element={<Impuestosespana/>}/>
        <Route path='/impuestosdubai' element={<Impuestosdubai/>}/>
        <Route path='/impuestosmalta' element={<Impuestosmalta/>}/>
        <Route path='/residenciainversorespana' element={<Residenciainversorespana/>}/>
        <Route path='/residenciainversordubai' element={<Residenciainversordubai/>}/>
        <Route path='/residenciainversormalta' element={<Residenciainversormalta/>}/>
        <Route path='/hotelmontereyespana' element={<Hotelmontereyespana/>}/>
        <Route path='/hoteldubai' element={<Hoteldubai/>}/>
        <Route path='/hotelmalta' element={<Hotelmalta/>}/>
        <Route path='/barbarselonaespana' element={<Barbarselonaespana/>}/>
        <Route path='/bardubai' element={<Bardubai/>}/>
        <Route path='/barmalta' element={<Barmalta/>}/>
        <Route path='/edificioespana' element={<Edificioespana/>}/>
        <Route path='/edificiodubai' element={<Edificiodubai/>}/>
        <Route path='/edificiomalta' element={<Edificiomalta/>}/>
        <Route path='/dubai' element={<Dubai/>}/>
        <Route path='*' element={<Error404/>}/>
      </Routes>
      <Footer />
</BrowserRouter>
  );
}

export default App;
