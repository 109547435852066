import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import home from "../assets/img/paises/dubai/1-2000x1000.jpg";


import { useTranslation } from "react-i18next";

export default function Impuestosdubai() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <BodyContainer>
      <body>


      <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                          <NavLink to="/">{t("navbar.page1")}</NavLink>
                        </li>
                      <li className="breadcrumb-item">
                          <NavLink to="/dubai">{t("body.texto6")}</NavLink>
                        </li>
                      <li className="breadcrumb-item">
                          <NavLink to="/infutildubai">{t("dubai.texto1")}</NavLink>
                        </li>
                        <li className="breadcrumb-item active">
                          {t("dubai.texto3")}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* /.container-fluid */}
              </section>


              <div className="card card-widget">

              <div
                          id="carouselExampleIndicators"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators"></ol>
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                className="d-block w-100"
                                src={home}
                                alt="First slide"
                              />
                            </div>
                          </div>
                        </div>

              <div
                          className="fl-row fl-row-full-width fl-row-bg-photo fl-node-6092c7848ba9d fl-row-default-height fl-row-align-center fl-row-bg-overlay fl-row-bg-fixed"
                          data-node="6092c7848ba9d"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-6092cabcec94c fl-col-group-equal-height fl-col-group-align-center"
                                data-node="6092cabcec94c"
                              >
                                <div
                                  className="fl-col fl-node-6092cabceca4d fl-col-small"
                                  data-node="6092cabceca4d"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-rich-text fl-node-6092d40dc8ecf"
                                      data-node="6092d40dc8ecf"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-rich-text">
                                        <h3>{t("irpf.texto1")}</h3>
                                        <h4>{t("irpf.texto2")}</h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        

                {/* /.card-header */}
                <div className="row">
                  <div className="col-md-12">
                    {/* Box Comment */}
                      {/* /.card-header */}
                      <div className="card-body">
                        <h4>{t("irpf.texto3")}</h4>
                          <ol>
                            <li> {t("irpf.texto4")}</li>
                            <li> {t("irpf.texto5")}</li>
                          </ol>
                          <h4>{t("irpf.texto6")}</h4>
                          <h4>{t("irpf.texto7")}</h4>
                          <ol>
                            <li> {t("irpf.texto8")}</li>
                              <ol>
                                <li> {t("irpf.texto9")}</li>
                                <li> {t("irpf.texto10")}</li>
                                <li> {t("irpf.texto11")}</li>
                                <li> {t("irpf.texto12")}</li>
                                <li> {t("irpf.texto13")}</li>
                                <li> {t("irpf.texto14")}</li>
                              </ol>
                          </ol>
                          <h4>{t("irpf.texto15")}</h4>
                          <h4>{t("irpf.texto16")}</h4>
                          <ol>
                            <li> {t("irpf.texto17")}</li>
                              <ol>
                                <li> {t("irpf.texto18")}</li>
                                <li> {t("irpf.texto19")}</li>
                              </ol>
                            <li> {t("irpf.texto20")}</li>
                              <ol>
                                <li> {t("irpf.texto21")}</li>
                                <li> {t("irpf.texto22")}</li>
                              </ol>
                            <li> {t("irpf.texto23")}</li>
                              <ol>
                                <li> {t("irpf.texto24")}</li>
                                <li> {t("irpf.texto25")}</li>
                                <li> {t("irpf.texto26")}</li>
                              </ol>
                          </ol>
                        <h4>{t("irpf.texto27")}</h4>
                      </div>
                      {/* /.card-footer */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
              </div>

        <a
          id="back-to-top"
          href="#"
          className="btn btn-primary back-to-top"
          role="button"
          aria-label="Scroll to top"
        >
          <i className="fas fa-chevron-up"></i>
        </a>
      </body>

      </BodyContainer>
    </>
  );
}

const BodyContainer = styled.body`
  background-color: #343a40;

h3{
  color:  #d4ac0d ;
  font-weight: 400;
  span{
      font-weight:bold;
      color: white;
  }
}


  h3 {
    color: #d4ac0d;
    font-weight: 700;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
