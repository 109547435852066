import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import espana1 from "../assets/img/paises/espana/1-2400x1600--esp.jpg";
import espana2 from "../assets/img/paises/espana/1-400x565-esp.jpg";
import dubai1 from "../assets/img/paises/dubai/3-400x565.jpg";
import dubai2 from "../assets/img/paises/dubai/3-2400x1600 (1).jpg";
import malta1 from "../assets/img/paises/malta/1-2400x1600 (1).jpg";
import malta2 from "../assets/img/paises/malta/1-400x565.jpg";
import home1 from "../assets/img/home/1-940x350-home.jpg";
import home2 from "../assets/img/home/2-940x350-home.jpg";
import home3 from "../assets/img/home/3-940x350-home.jpg";
import home4 from "../assets/img/home/4-940x350-home.jpg";
import marrueco1 from "../assets/img/paises/marrueco/2-2400x1600 (1).jpg";
import marrueco2 from "../assets/img/paises/marrueco/2-400x565.jpg";

import { useTranslation } from "react-i18next";

export default function Body() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <BodyContainer>
        <body>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            className="col-sm-12 col-md-12 fl-page-logo-wrap"
            id="yui_3_18_1_1_1719501602214_1157"
          >
            <div
              id="fl-main-content"
              className="fl-page-content"
              itemProp="mainContentOfPage"
              role="main"
            >
              <div
                className="fl-content-full container"
                id="yui_3_18_1_1_1719501602214_1533"
              >
                <div className="row" id="yui_3_18_1_1_1719501602214_1532">
                  <div
                    className="fl-content col-md-12"
                    id="yui_3_18_1_1_1719501602214_1531"
                  >
                    <div
                      className="fl-post post-2 page type-page status-publish hentry"
                      id="fl-post-2"
                      itemScope="itemscope"
                      itemType="https://schema.org/CreativeWork"
                    >
                      <div
                        className="fl-post-content clearfix"
                        itemProp="text"
                        id="yui_3_18_1_1_1719501602214_1530"
                      >
                        <div
                          className="fl-builder-content fl-builder-content-2 fl-builder-content-primary fl-builder-global-templates-locked"
                          data-post-id={2}
                          id="yui_3_18_1_1_1719501602214_1529"
                        >
                          <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <ol className="carousel-indicators"></ol>
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img
                                  className="d-block w-100"
                                  src={home1}
                                  alt="First slide"
                                />
                                <div className="carousel-caption d-none d-md-block"></div>
                              </div>
                              <div className="carousel-item">
                                <img
                                  className="d-block w-100"
                                  src={home2}
                                  alt="Third slide"
                                />
                                <div className="carousel-caption d-none d-md-block"></div>
                              </div>
                              <div className="carousel-item">
                                <img
                                  className="d-block w-100"
                                  src={home3}
                                  alt="Third slide"
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  className="d-block w-100"
                                  src={home4}
                                  alt="Third slide"
                                />
                              </div>
                            </div>
                            <a
                              className="carousel-control-prev"
                              href="#carouselExampleIndicators"
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="carousel-control-next"
                              href="#carouselExampleIndicators"
                              role="button"
                              data-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Next</span>
                            </a>
                          </div>

                          <div
                            className="fl-row fl-row-full-width fl-row-bg-none fl-node-6092cb08048ee fl-row-default-height fl-row-align-center"
                            data-node="6092cb08048ee"
                          >
                            <div className="fl-row-content-wrap">
                              <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                <div
                                  className="fl-col-group fl-node-6092cb0809677"
                                  data-node="6092cb0809677"
                                ></div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="fl-row fl-row-full-width fl-row-bg-none fl-node-6094070f68f85 fl-row-default-height fl-row-align-center"
                            data-node="6094070f68f85"
                          >
                            <div className="fl-row-content-wrap">
                              <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                <div
                                  className="fl-col-group fl-node-60940758b1b45"
                                  data-node="60940758b1b45"
                                >
                                  <div
                                    className="fl-col fl-node-60940758b1c0d"
                                    data-node="60940758b1c0d"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-pp-hover-cards-2 fl-node-60940758b1ac0 fl-visible-desktop fl-visible-large fl-visible-medium"
                                        data-node="60940758b1ac0"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-hover-card-wrap pp-clearfix">
                                            <div
                                              className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/espana"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={espana2}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                            <div
                                              className="pp-hover-card pp-hover-card-1 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/dubai"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={dubai1}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                            <div
                                              className="pp-hover-card pp-hover-card-2 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/marrueco"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={marrueco2}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                            <div
                                              className="pp-hover-card pp-hover-card-3 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/malta"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={malta2}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fl-col-group fl-node-6094073c27040"
                                  data-node="6094073c27040"
                                >
                                  <div
                                    className="fl-col fl-node-6094073c2716d fl-col-small"
                                    data-node="6094073c2716d"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-pp-hover-cards-2 fl-node-60941cd1acd30 fl-visible-mobile"
                                        data-node="60941cd1acd30"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-hover-card-wrap pp-clearfix">
                                            <div
                                              className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/espana"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={espana1}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-module fl-module-pp-smart-button fl-node-60940fe77dcb1"
                                        data-node="60940fe77dcb1"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-button-wrap pp-button-width-full">
                                            <NavLink
                                              to="/espana"
                                              target="_self"
                                              className="pp-button"
                                              role="button"
                                              aria-label={t("body.texto5")}
                                            >
                                              <span className="pp-button-text">
                                                {t("body.texto5")}
                                              </span>
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="fl-col fl-node-6094073c27170 fl-col-small"
                                    data-node="6094073c27170"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c0b95ddc fl-visible-mobile"
                                        data-node="60941c0b95ddc"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-hover-card-wrap pp-clearfix">
                                            <div
                                              className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/dubai"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={dubai2}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-module fl-module-pp-smart-button fl-node-60940fea568ee"
                                        data-node="60940fea568ee"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-button-wrap pp-button-width-full">
                                            <NavLink
                                              to="/dubai"
                                              target="_self"
                                              className="pp-button"
                                              role="button"
                                              aria-label={t("body.texto6")}
                                            >
                                              <span className="pp-button-text">
                                                {t("body.texto6")}
                                              </span>
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="fl-col fl-node-6094106250e49 fl-col-small"
                                    data-node="6094106250e49"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c8040635 fl-visible-mobile"
                                        data-node="60941c8040635"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-hover-card-wrap pp-clearfix">
                                            <div
                                              className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/marrueco"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={marrueco1}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-module fl-module-pp-smart-button fl-node-6094106250e4b"
                                        data-node="6094106250e4b"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-button-wrap pp-button-width-full">
                                            <NavLink
                                              to="/marrueco"
                                              target="_self"
                                              className="pp-button"
                                              role="button"
                                              aria-label={t("body.texto7")}
                                            >
                                              <span className="pp-button-text">
                                                {t("body.texto7")}
                                              </span>
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="fl-col fl-node-6094073c27172 fl-col-small"
                                    data-node="6094073c27172"
                                  >
                                    <div className="fl-col-content fl-node-content">
                                      <div
                                        className="fl-module fl-module-pp-hover-cards-2 fl-node-60941c4861ebb fl-visible-mobile"
                                        data-node="60941c4861ebb"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-hover-card-wrap pp-clearfix">
                                            <div
                                              className="pp-hover-card pp-hover-card-0 style-9 pp-clearfix"
                                              onclick
                                              tabIndex={0}
                                            >
                                              <NavLink
                                                to="/malta"
                                                className="pp-more-link-container"
                                                target="_self"
                                              >
                                                <img
                                                  decoding="async"
                                                  src={malta1}
                                                  className="pp-hover-card-image"
                                                  alt
                                                />
                                                <div className="pp-hover-card-inner">
                                                  <div className="pp-hover-card-inner-wrap">
                                                    <div className="pp-hover-card-content">
                                                      <div className="pp-hover-card-title-wrap">
                                                        <h2 className="pp-hover-card-title" />
                                                      </div>
                                                      <div className="pp-hover-card-description">
                                                        <div className="pp-hover-card-description-inner">
                                                          <p>
                                                            {t("body.texto4")}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-hover-card-overlay" />
                                              </NavLink>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-module fl-module-pp-smart-button fl-node-60940fed765ef"
                                        data-node="60940fed765ef"
                                      >
                                        <div className="fl-module-content fl-node-content">
                                          <div className="pp-button-wrap pp-button-width-full">
                                            <NavLink
                                              to="/malta"
                                              target="_self"
                                              className="pp-button"
                                              role="button"
                                              aria-label="Malta"
                                            >
                                              <span className="pp-button-text">
                                                {t("body.texto8")}
                                              </span>
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a
            id="back-to-top"
            href="#"
            className="btn btn-primary back-to-top"
            role="button"
            aria-label="Scroll to top"
          >
            <i className="fas fa-chevron-up"></i>
          </a>
        </body>
      </BodyContainer>
    </>
  );
}

const BodyContainer = styled.body`
  background-color: #343a40;

  h3 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
  }

  h3 {
    color: #d4ac0d;
    font-weight: 700;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
