import React from 'react'
import Body from './Body';

export default function Content() {
  return (
    <div className="content-wrapper">
      
      <Body/>      

    </div>

  )
}
