import React from 'react'
import Content from './Content'
import styled from "styled-components";

export default function Home() {
  return (
    <>
      <DivContainer>
        <Content/>
      </DivContainer>
    </>
  )
}

const DivContainer = styled.div`
  background-color: #343a40;
`;
