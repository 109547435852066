import React from "react";

export default function Error404() {
  return (
    <>
      <div className="main-header">
        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
              <div className="">
                  <div className="row">
                      <h1>
                        <b>Page not found.</b>
                      </h1>
                  </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 d-flex align-items-stretch flex-column"></div>
          </div>
        </div>
      </div>
    </>
  );
}
